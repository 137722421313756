<template>
  <k-crud-layout :with-search="false">
    <template #header>{{ $t('shoppingCart.pageTitle') }}</template>
    <template #flow-actions>
      <v-btn color="primary"
             :disabled="!canSubmit"
             depressed
             tile
             @click="onCheckoutClick">
        {{ $tc('shoppingCart.requestOffer') }}
      </v-btn>
    </template>
    <template #sheet-prepend>
      <v-row justify="end" align-content="center">
        <v-col class="pl-9 pt-8">
          <v-alert transition="fade-transition" outlined type="warning" border="left" v-if="mandatoryAddonsError">
            {{ $t('shoppingCart.addons.dialog.notFilledMandatory') }}
          </v-alert>
        </v-col>
        <VSpacer/>
        <v-col cols="auto" v-if="shoppingCart">
          <div class="d-flex box--total">
            <div class="d-inline-flex align-self-center pl-6 pr-8">
              <div class="mt-1 mr-4 font-weight-medium text-h3">{{ $t('shoppingCart.total') }}:</div>
              <i18n-n :tag="false"
                      :value="totalAmount"
                      :format="{ key: 'currency' }"
                      class="d-inline-flex">
                <template #currency="{currency}">
                  <div class="text-h1 font-weight-medium">{{ currency }}</div>
                </template>
                <template #group="{group}">
                  <div class="text-h1 font-weight-medium">{{ group }}</div>
                </template>
                <template #integer="{integer}">
                  <div class="text-h1 font-weight-medium">{{ integer }}</div>
                </template>
                <template #decimal="{decimal}">
                  <div class="text-h1 font-weight-medium">{{ decimal }}</div>
                </template>
                <template #fraction="{fraction}">
                  <div class="text-h3 font-weight-medium mt-1">{{ fraction }}</div>
                </template>
              </i18n-n>
              <LocalePrice :price="shoppingCart.localAmount"/>
            </div>
          </div>
        </v-col>
      </v-row>
    </template>
    <template #view.list>
      <v-data-table :headers="crudHeaders"
                    :items="items"
                    :loading="isPending || isPendingSubmit"
                    :footer-props="{itemsPerPageOptions: [5,10,15,25],
                   itemsPerPageText: $t('global.$vuetify.dataFooter.itemsPerPageText'),
                   pageText: $t('global.$vuetify.dataFooter.pageText')}">
        <template #item="{item}">
          <tr class="order-data">
            <td rowspan="2">
              <VImg :src="`${item.image}&type=thumbnail`"
                    :lazy-src="`${item.image}&type=lazy`"
                    height="100px"
                    max-width="100px"
                    contain/>
            </td>
            <td style="border-bottom: 0;">
              {{ item.name }}
            </td>
            <td style="border-bottom: 0;">
              {{ item.sku }}
            </td>
            <td style="border-bottom: 0; width: 1%" class="no-table-separator">
              <k-btn v-if="hasAddOns(item) && !optionalAddOns(item) && !mandatoryAddons(item)"
                     :loading="isAddonsLoading.includes(item.id)"
                     :disabled="isAddonsLoading.length > 0 && !isAddonsLoading.includes(item.id)"
                     small
                     outlined
                     color="secondary"
                     @click="openAddons(item.id)">
                <v-icon left>$edit</v-icon>
                {{ $t('shoppingCart.addons.modifyAddons') }}
              </k-btn>
              <k-btn small color="warning"
                     v-if="mandatoryAddons(item)"
                     :loading="isAddonsLoading.includes(item.id)"
                     :disabled="isAddonsLoading.length > 0 && !isAddonsLoading.includes(item.id)"
                     @click="openAddons(item.id)">
                <v-icon left>$warning</v-icon>
                {{ $t('shoppingCart.addons.mandatoryAddons') }}
              </k-btn>
              <k-btn small outlined color="secondary"
                     v-if="optionalAddOns(item)"
                     :loading="isAddonsLoading.includes(item.id)"
                     :disabled="isAddonsLoading.length > 0 && !isAddonsLoading.includes(item.id)"
                     @click="openAddons(item.id)">
                {{ $t('shoppingCart.addons.optionalAddons') }}
              </k-btn>
            </td>
            <td class="no-table-separator" style="border-bottom: 0">
              {{item.quantity}}
            </td>
            <td style="text-align: right; width: 1%; white-space: nowrap;">
              <div class="mb-4">
                <LocalePrice :price="item.localPrice" badge-class="mr-2"/>
                {{ $n(item.price, 'currency') }}
              </div>
            </td>
            <td rowspan="2" style="text-align: right; width: 1%; white-space: nowrap;position: relative">
              <div class="mb-4">
                <LocalePrice :price="item.localLineTotal" badge-class="mr-2"/>
                {{ $n(item.lineTotal, 'currency') }}
              </div>

              <template v-if="hasAddOns(item)">
                <div>{{ $t('orders.details.addons') }}:</div>
                <div class="pl-8">
                  <LocalePrice :price="item.addOns.localTotalPrice" badge-class="mr-2"/>
                  <span>{{ $n(item.addOns.totalPrice, 'currency') }}</span>
                </div>
              </template>
              <div class="custom-top-border"></div>
            </td>
            <td rowspan="2" style="width: 1%">
              <div class="crud-actions">
                <v-btn :disabled="isPendingRemove.includes(item.id)"
                       color="text--secondary"
                       icon
                       @click.stop="remove(item)">
                  <v-icon>$delete</v-icon>
                </v-btn>
              </div>
            </td>
          </tr>
          <tr class="order-addons">
            <td colspan="5" style="border-top: 0; ">
              <div v-for="product in item.addOns.products" :key="product.id" class="d-inline ma-3 text-center">
                <v-badge :value="product.quantity" color="black" :content="product.quantity">
                  <VImg :src="`${product.image}&type=thumbnail`"
                        :lazy-src="`${product.image}&type=lazy`"
                        height="50px"
                        max-width="50px"
                        contain/>
                  {{ product.sku }}
                </v-badge>
              </div>
            </td>
          </tr>
          <AddonsForm v-if="isAddonsDialogOpen === item.id"
                      v-model="isAddonsDialogOpen"
                      :list="addonsList[item.id]"
                      :item="item"
                      @reload-line="refreshOrderLine"/>
        </template>

      </v-data-table>
    </template>

  </k-crud-layout>
</template>

<script lang="js">
import { EventBus } from '@/application/EventBus.js';
import KBtn from '@/components/KButton.vue';
import KCrudLayout from '@/components/layout/KCrudLayout.vue';
import { getAddons, index, remove } from '@/modules/shoppingCart/api';
import AddonsForm from '@/modules/shoppingCart/components/addonsForm.vue';
import { mapActions, mapGetters } from 'vuex';
import LocalePrice from '@/components/LocalePrice.vue';
import LocalCurrencyFilter from '@/application/mixins/validLocalCurrency.js';

export default {
  name: 'ShoppingCartTable',
  components: {
    LocalePrice,
    AddonsForm,
    KBtn,
    KCrudLayout,
  },
  data() {
    return {
      shoppingCart: null,
      isPending: false,
      isPendingSubmit: false,
      isPendingRemove: [],
      items: [],
      addonsList: {},
      isAddonsDialogOpen: false,
      isAddonsLoading: [],
      mandatoryAddonsError: false,
    };
  },
  watch: {
    async currentStoreId() {
      const { data: { data } } = await this.indexRequest();
      this.items = data;
    },
  },
  computed: {
    ...mapGetters('settings', { currentStoreId: 'getCurrentStoreId' }),
    hasInvalidOrderLines() {
      return this.shoppingCart?.orderLines
          .filter(orderLine => orderLine.addOns?.products?.length < orderLine.minRequiredAddons)
          .length > 0;
    },
    crudHeaders() {
      return [
        {
          value: 'image',
          text: '',
          width: 132,
          sortable: false,
        },
        {
          value: 'name',
          text: this.$t('shoppingCart.headers.itemName'),
          cellClass: 'py-3',
        },
        {
          value: 'sku',
          text: this.$t('shoppingCart.headers.itemCode'),
          cellClass: 'py-3',
        },
        {
          value: 'addons',
          text: '',
          sortable: false,
        },
        {
          value: 'quantity',
          text: this.$t('shoppingCart.headers.quantity'),
          cellClass: 'py-3',
        },
        {
          value: 'price',
          text: this.$t('shoppingCart.headers.salesPrice'),
          cellClass: 'py-3',
        },
        {
          value: 'linePrice',
          text: this.$t('shoppingCart.headers.linePrice'),
          cellClass: 'py-3',
        },
        {
          value: 'actions',
          text: '',
          width: '0%',
          sortable: false,
        },
      ];
    },
    canSubmit() {
      return this.shoppingCart?.orderLines?.length > 0;
    },
    totalAmount() {
      return this.shoppingCart?.amount || 0;
    },
  },
  created() {
    this.getShoppingCart();
  },
  methods: {
    ...mapActions('shoppingCart', ['fetchAmountInCart']),
    onCheckoutClick() {
      if (this.hasInvalidOrderLines) {
        this.mandatoryAddonsError = true;
        setTimeout(() => this.mandatoryAddonsError = false, 8000);
      } else {
        this.$router.push('checkout');
      }
    },
    async getShoppingCart() {
      const { data: { data } } = await this.indexRequest();
      this.items = data;
    },
    async indexRequest(...args) {
      try {
        const {
          data: {
            data,
            meta,
          },
        } = await index(this.currentStoreId, ...args);
        this.shoppingCart = {
          ...data,
          amount: data.amount,
          localAmount: LocalCurrencyFilter(data.localAmount),
          orderLines: data.orderLines.map(orderLine => ({
            ...orderLine,
            price: orderLine.price,
            lineTotal: orderLine.lineTotal,
            localLineTotal: orderLine.localLineTotal,
            localPrice: LocalCurrencyFilter(orderLine.localPrice),
            addOns: {
              ...orderLine['add-ons'],
              totalPrice: orderLine['add-ons'].totalPrice,
              localTotalPrice: LocalCurrencyFilter(orderLine['add-ons'].localTotalPrice),
              products: orderLine['add-ons'].products.map(product => ({
                ...product,
                price: product.price,
                localPrice: LocalCurrencyFilter(product.localPrice),
              })),
            },
          })),
        };

        return {
          data: {
            meta: meta,
            data: this.shoppingCart.orderLines,
          },
        };
      } catch (e) {
        EventBus.$emit('snackbar', {
          color: 'error',
          text: this.$t('errors.defaultErrorWrapper', [this.$t('errors.retrieveCart')]),
        });
      }
    },
    async remove(item) {
      this.isPendingRemove = [...this.isPendingRemove, item.id];
      try {
        await remove(item);
        EventBus.$emit('snackbar', {
          color: 'success',
          text: this.$t('actions.resourceRemoved', { resource: item.name }),
        });
        await this.refreshOrderLine();
      } catch (e) {
        EventBus.$emit('snackbar', {
          color: 'error',
          text: this.$t('errors.defaultErrorWrapper', [this.$t('errors.removeOrder', [item.name])]),
        });
      }
      await this.fetchAmountInCart(this.currentStoreId);
      this.isPendingRemove = this.isPendingRemove.filter(id => id !== item.id);
    },
    async openAddons(orderLineId) {
      if (this.isAddonsLoading.includes(orderLineId)) return;

      if (!(orderLineId in this.addonsList)) {
        this.isAddonsLoading = [...this.isAddonsLoading, orderLineId];
        try {
          const { data: { data } } = await getAddons(orderLineId);
          this.addonsList[orderLineId] = data.map(product => {
            return {
              ...product,
              price: product.price,
              localPrice: LocalCurrencyFilter(product.localPrice),
            };
          });
        } catch (e) {
          EventBus.$emit('snackbar', {
            color: 'error',
            text: this.$t('errors.defaultErrorWrapper', [this.$t('errors.getAddonsList')]),
          });
        }
        this.isAddonsLoading = this.isAddonsLoading.filter(id => id !== orderLineId);
      }
      this.isAddonsDialogOpen = orderLineId;
    },
    async refreshOrderLine() {
      const { data: { data } } = await this.indexRequest();
      this.items = data;
    },
    hasAddOns(item) {
      return item.productHasAddons;
    },
    optionalAddOns(item) {
      return this.hasAddOns(item) && item.minRequiredAddons === 0 && item['add-ons'].products.length === 0;
    },
    mandatoryAddons(item) {
      return this.hasAddOns(item) && item.minRequiredAddons > 0 && item['add-ons'].products.length < item.minRequiredAddons;
    },
  },
};
</script>

<style lang="scss" scoped>
.box--total {
  min-width:    240px;
  height:       80px;
  box-sizing:   border-box;
  border:       1px solid rgba(0, 0, 0, 0.25);
  margin-right: 24px;
  margin-top:   24px;
}

::v-deep {
  .box--total {
    position: relative;

    .locale-price-badge {
      &.v-chip.v-chip--outlined.v-chip.v-chip {
        background: white !important;
      }

      position: absolute;
      z-index:  1;
      bottom:   -12px;
    }
  }

  .v-data-table > .v-data-table__wrapper > table > tbody > tr > td {
    vertical-align: top;
    padding-top:    8px
  }

  .v-data-table > .v-data-table__wrapper > table > tbody > tr:hover:not(.v-data-table__expanded__content):not(.v-data-table__empty-wrapper) {
    background-color: inherit;
  }

  .v-data-table-header > tr > th {
    white-space: nowrap;
  }

  tbody td + td:not(.no-table-separator) {
    border-image:       linear-gradient(to bottom, rgba(0, 0, 0, 0) 11px, rgba(0, 0, 0, 0.25) 11px, rgba(0, 0, 0, 0.25) calc(28px), rgba(0, 0, 0, 0) calc(28px - 11px));
    border-image-slice: 1;
    border-left-style:  solid;
    border-left-width:  1px;
  }

  tbody tr:not(:first-child) td .custom-top-border {
    position: absolute;
    top: -1px;
    height: 1px;
    background: rgba(0, 0, 0, 0.12);
    left: -1px;
    right: -1px;
  }
}
</style>
